import { brandConfig } from './brand.config'
import { growthBookConfig } from './growthBook.config'
import { loginConfig } from './login.config'
import { rewardClientNames } from './rewardClientsEnvConfig'

const conf = {
  prod: {
    redisHost: 'acko-seo-cache.internal.acko.com',
    redisPort: '6379',
    segmentKey: {
      ackoSeo: 'HXYpaTGkLGPf8NCthRHd56vpihGevRk6',
      ackoDrive: 'diuEtVmmIty3lnYuipmsohzpEGmGGR3J',
      homePage: '37Tcc2RQFS0OPMFTLUJWcuwPUXShBpHl',
      ackoBrand: 'h2tIdlVfvi0CmHIgXIA9K3OEJAT6IR5N',
      ackoCareer: 'FAHnZoRSv5GNU2BmEqUqddQTIB4Rked7'
    },
    communicationServiceUrl: 'https://communication-service.external.live.acko.com',
    rewardsServiceUrl: 'https://central-acko-rewards.internal.live.acko.com',
    authTokenServiceUrl: 'https://central-auth-prod.internal.live.acko.com/realms/acko/protocol/openid-connect',
    entityService: 'https://entity-service.internal.acko.com',
    hcaptchaSitekey: '63e15280-5462-4d9d-9275-4d0632a362c3',
    healthUserServiceUrl: 'https://health-presentation-layer.internal.live.acko.com',
    ackoDriveEndpoint: 'https://workspace.ackodrive.com',
    ackoBaseUrl: 'https://www.acko.com',
    ackoDriveBaseUrl: 'https://www.ackodrive.com',
    rewardClientNames: rewardClientNames.prod,
    brandConfig: brandConfig.prod,
    loginConfig: loginConfig.prod,
    fastagFetchBalance: 'https://central-biller-service.internal.live.acko.com/biller-service/api/v1/recharges',
    fastagBiller:
      'https://central-biller-service.internal.live.acko.com/biller-service/api/v1/recharges/fastag/billers',
    autoExternal: 'https://auto-external-service.live.acko.com/vehicle_service/api/v1/vehicles',
    growthBookConfig: growthBookConfig.prod,
    ackontentBaseUrl: 'https://www.acko.com/ackontent/recommendation/api/v2',
    fireflyAdminUrl: 'http://firefly-admin.internal.live.acko.com/',
    puc: 'https://platform-vas-puc-service.internal.live.acko.com',
    apiService: 'https://www.acko.com',
    vehicleInfoService: 'https://pricing-vehicle-information-service.live.acko.com',
    autoAssetService: 'https://auto-asset-management.live.acko.com',
  },
  'pre-prod': {
    redisHost: 'acko-seo-cache.internal.acko.com',
    redisPort: '6379',
    segmentKey: {
      ackoSeo: 'HXYpaTGkLGPf8NCthRHd56vpihGevRk6',
      ackoDrive: 'diuEtVmmIty3lnYuipmsohzpEGmGGR3J',
      homePage: '37Tcc2RQFS0OPMFTLUJWcuwPUXShBpHl',
      ackoCareer: 'FAHnZoRSv5GNU2BmEqUqddQTIB4Rked7'
    },
    communicationServiceUrl: 'https://communication-service.external.live.acko.com',
    rewardsServiceUrl: 'https://central-acko-rewards.internal.live.acko.com',
    authTokenServiceUrl: 'https://central-auth-prod.internal.live.acko.com/realms/acko/protocol/openid-connect',
    entityService: 'https://entity-service.internal.acko.com',
    hcaptchaSitekey: '63e15280-5462-4d9d-9275-4d0632a362c3',
    healthUserServiceUrl: 'https://health-presentation-layer.internal.live.acko.com',
    ackoDriveEndpoint: 'https://workspace.ackodrive.com',
    ackoBaseUrl: 'https://www.acko.com',
    ackoDriveBaseUrl: 'https://www.ackodrive.com',
    brandConfig: brandConfig.prod,
    rewardClientNames: rewardClientNames.prod,
    loginConfig: loginConfig.prod,
    fastagFetchBalance: 'https://central-biller-service.internal.live.acko.com/biller-service/api/v1/recharges',
    fastagBiller:
      'https://central-biller-service.internal.live.acko.com/biller-service/api/v1/recharges/fastag/billers',
    autoExternal: 'https://auto-external-service.live.acko.com/vehicle_service/api/v1/vehicles',
    growthBookConfig: growthBookConfig.prod,
    ackontentBaseUrl: 'https://www.acko.com/ackontent/recommendation/api/v2',
    fireflyAdminUrl: 'http://firefly-admin.internal.live.acko.com/',
    puc: 'https://platform-vas-puc-service.internal.live.acko.com',
    apiService: 'https://www.acko.com',
    vehicleInfoService: 'https://pricing-vehicle-information-service.live.acko.com',
    autoAssetService: 'https://auto-asset-management.live.acko.com',
  },
  master: {
    redisHost: 'acko-seo-cache.internal.ackodev.com',
    redisPort: '6379',
    segmentKey: {
      ackoSeo: 'LHxVP6yUVPd1An7vjC0KHC57E2I9jhA6',
      ackoDrive: 'g3ap5Yu91V2qsHVvBpzaGvTY3DG0szUJ',
      homePage: 'Z3eilwRIxGJtrkzoyMSCET0GdRVuKzYK',
      ackoBrand: 'thfdUv8SdqfVn1y9VPeiJorqR1qaXECQ',
      ackoCareer: 'FAHnZoRSv5GNU2BmEqUqddQTIB4Rked7'
    },
    communicationServiceUrl: 'https://communication-service.external.ackodev.com',
    rewardsServiceUrl: 'https://central-acko-rewards-uat.internal.ackodev.com',
    authTokenServiceUrl: 'https://central-auth-uat.internal.ackodev.com/realms/acko-uat/protocol/openid-connect',
    entityService: 'https://entity-service.internal.ackodev.com',
    hcaptchaSitekey: '585c1d16-fccf-405e-8917-31308fa419f2',
    healthUserServiceUrl: 'https://health-presentation-layer.internal.ackodev.com',
    ackoDriveEndpoint: 'https://workspace.internal.ackodrive.com',
    ackoBaseUrl: 'https://www.ackodev.com',
    ackoDriveBaseUrl: 'https://www.ackodrive.com',
    brandConfig: brandConfig.master,
    rewardClientNames: rewardClientNames.master,
    loginConfig: loginConfig.master,
    fastagFetchBalance: 'https://www.ackodev.com/biller-service/api/v1/recharges',
    fastagBiller: 'https://www.ackodev.com/biller-service/api/v1/recharges/fastag/billers',
    autoExternal: 'https://auto-external-service-uat.internal.ackodev.com/vehicle_service/api/v1/vehicles',
    growthBookConfig: growthBookConfig.master,
    ackontentBaseUrl: 'https://www.ackodev.com/ackontent/recommendation/api/v2',
    fireflyAdminUrl: 'http://firefly-admin-uat.internal.ackodev.com/',
    puc: 'https://platform-vas-puc-service.internal.ackodev.com',
    apiService: 'https://www.ackodev.com',
    vehicleInfoService: 'https://pricing-vehicle-information-service-uat.internal.ackodev.com',
    autoAssetService: 'https://auto-asset-management-dev.internal.ackodev.com',
  },
  local: {
    redisHost: 'acko-seo-cache.internal.ackodev.com',
    redisPort: '6379',
    segmentKey: {
      ackoSeo: 'LHxVP6yUVPd1An7vjC0KHC57E2I9jhA6',
      ackoDrive: 'g3ap5Yu91V2qsHVvBpzaGvTY3DG0szUJ',
      homePage: 'Z3eilwRIxGJtrkzoyMSCET0GdRVuKzYK',
      ackoBrand: 'thfdUv8SdqfVn1y9VPeiJorqR1qaXECQ',
      ackoCareer: 'FAHnZoRSv5GNU2BmEqUqddQTIB4Rked7'
    },
    communicationServiceUrl: 'https://communication-service.external.ackodev.com',
    rewardsServiceUrl: 'http://localhost:5030',
    authTokenServiceUrl: 'https://central-auth-uat.internal.ackodev.com/realms/acko-uat/protocol/openid-connect',
    entityService: 'https://entity-service.internal.ackodev.com',
    hcaptchaSitekey: '585c1d16-fccf-405e-8917-31308fa419f2',
    healthUserServiceUrl: 'https://health-presentation-layer.internal.ackodev.com',
    ackoDriveEndpoint: 'https://workspace.internal.ackodrive.com',
    ackoBaseUrl: 'https://www.ackodev.com',
    ackoDriveBaseUrl: 'https://www.ackodrive.com',
    brandConfig: brandConfig.local,
    rewardClientNames: rewardClientNames.local,
    loginConfig: loginConfig.local,
    fastagFetchBalance: 'https://www.ackodev.com/biller-service/api/v1/recharges',
    fastagBiller: 'https://www.ackodev.com/biller-service/api/v1/recharges/fastag/billers',
    autoExternal: 'https://auto-external-service-uat.internal.ackodev.com/vehicle_service/api/v1/vehicles',
    growthBookConfig: growthBookConfig.local,
    ackontentBaseUrl: 'https://www.ackodev.com/ackontent/recommendation/api/v2',
    fireflyAdminUrl: 'http://firefly-admin-uat.internal.ackodev.com/',
    puc: 'https://platform-vas-puc-service.internal.ackodev.com',
    apiService: 'https://www.ackodev.com',
    vehicleInfoService: 'https://pricing-vehicle-information-service-uat.internal.ackodev.com',
    autoAssetService: 'https://auto-asset-management-dev.internal.ackodev.com',
  },
  dev: {
    redisHost: 'acko-seo-cache.internal.ackodev.com',
    redisPort: '6379',
    segmentKey: {
      ackoSeo: 'LHxVP6yUVPd1An7vjC0KHC57E2I9jhA6',
      ackoDrive: 'g3ap5Yu91V2qsHVvBpzaGvTY3DG0szUJ',
      homePage: 'Z3eilwRIxGJtrkzoyMSCET0GdRVuKzYK',
      ackoBrand: 'thfdUv8SdqfVn1y9VPeiJorqR1qaXECQ',
      ackoCareer: 'FAHnZoRSv5GNU2BmEqUqddQTIB4Rked7'
    },
    communicationServiceUrl: 'https://communication-service.external.ackodev.com',
    rewardsServiceUrl: 'https://central-acko-rewards-uat.internal.ackodev.com',
    authTokenServiceUrl: 'https://central-auth-uat.internal.ackodev.com/realms/acko-uat/protocol/openid-connect',
    entityService: 'https://entity-service.internal.ackodev.com',
    hcaptchaSitekey: '585c1d16-fccf-405e-8917-31308fa419f2',
    healthUserServiceUrl: 'https://health-presentation-layer.internal.ackodev.com',
    ackoDriveEndpoint: 'https://workspace.internal.ackodrive.com',
    ackoBaseUrl: 'https://www.ackodev.com',
    ackoDriveBaseUrl: 'https://www.ackodrive.com',
    brandConfig: brandConfig.dev,
    rewardClientNames: rewardClientNames.dev,
    loginConfig: loginConfig.dev,
    fastagFetchBalance: 'https://www.ackodev.com/biller-service/api/v1/recharges',
    fastagBiller: 'https://www.ackodev.com/biller-service/api/v1/recharges/fastag/billers',
    autoExternal: 'https://auto-external-service-uat.internal.ackodev.com/vehicle_service/api/v1/vehicles',
    growthBookConfig: growthBookConfig.dev,
    ackontentBaseUrl: 'https://www.ackodev.com/ackontent/recommendation/api/v2',
    fireflyAdminUrl: 'http://firefly-admin-uat.internal.ackodev.com/',
    puc: 'https://platform-vas-puc-service.internal.ackodev.com',
    apiService: 'https://www.ackodev.com',
    vehicleInfoService: 'https://pricing-vehicle-information-service-uat.internal.ackodev.com',
    autoAssetService: 'https://auto-asset-management-dev.internal.ackodev.com',
  },
}

export const getConfig = envValue => conf[envValue]
