/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-template */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable no-console */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { getCookie, setCookie } from 'cookies-next'
import { v4 as uuidv4 } from 'uuid'
import R2D2ServiceApi from '@services/r2d2Service'
import { FETCH_TIMEOUT, TENANT_TYPE } from '@components/Ackodrive/common/constants'
import { getR2d2Payload } from './r2d2Payload'

export const getTrackerId = tenant => {
  const trackerid = tenant === TENANT_TYPE.ACKODRIVE ? getCookie('eventTrackerUserId') : getCookie('trackerid')
  if (typeof trackerid === 'string') {
    return trackerid
  } else if (!trackerid) {
    const trackerid = uuidv4()
    if (tenant === TENANT_TYPE.ACKODRIVE) {
      setCookie('eventTrackerUserId', trackerid, {
        maxAge: 365 * 24 * 60 * 60,
        sameSite: 'none',
        path: '/',
        secure: true,
      })
    } else {
      setCookie('trackerid', trackerid, { maxAge: 365 * 24 * 60 * 60, sameSite: 'none', path: '/', secure: true })
    }

    return trackerid
  }
  return ''
}

export const extractComponentObject = (data, componentType) => {
  if (!data || typeof data !== 'object' || typeof data[Symbol.iterator] !== 'function') {
    return null
  }

  for (const item of data) {
    if (item.__component === componentType) {
      return item
    }
  }
  return null
}

export const extractOnlyFAQResults = (data, relation) => {
  const results: any = []

  data?.forEach(item => {
    if (item.__component === relation) {
      item.Content.data.forEach(val => {
        if (val?.attributes?.isFAQ) {
          results.push(...val?.attributes?.accordionCards)
        }
      })
    }
  })

  return results
}

export const extractAllChildComponentObjects = (data, relation) => {
  const results: any = []

  // Iterate over each item in the data array
  data?.forEach(item => {
    if (item.__component === relation) {
      item.Content.data.forEach(val => {
        results.push(...val?.attributes?.accordionCards)
      })
    }
  })

  return results
}

export const getChildComponent = (parent, relation) => parent?.filter(item => item?.__component === relation) || []

export function stripHtmlInlineCssTags(htmlString: string): string {
  if (!htmlString) return ''

  const formattedString = htmlString
    .replace(/style="[^"]*"/g, '') // Remove inline styles
    .replace(/&nbsp;/g, ' ') // Replace non-breaking spaces with regular spaces
    .replace(/<li>\s*/g, '• ') // Replace <li> with bullet points
    .replace(/<\/li>\s*/g, ' ') // Remove closing <li> tags and trailing spaces
    .replace(/<\/?[^>]+(>|$)/g, '') // Strip all other HTML tags

  return formattedString.trim()
}

export const convertToStructuredData = arrayOfObjects =>
  arrayOfObjects.map(obj => ({
    '@type': 'Question',
    name: stripHtmlInlineCssTags(obj.accordionHeading),
    acceptedAnswer: {
      '@type': 'Answer',
      text: stripHtmlInlineCssTags(obj.accordionContent),
    },
  }))

export const isLoggedInUtil = cookie => {
  let isUserLoggedIn = false
  ;(cookie || '').split(';').forEach(value => {
    if (value?.trim()?.indexOf('user_id=') === 0 && value?.trim().split('=')[1]?.length > 0) {
      isUserLoggedIn = true
    }
  })
  return isUserLoggedIn
}

export function cookie(key: string, value?: string) {
  if (typeof document === 'undefined') return
  if (typeof key === 'string' && typeof value === 'string') {
    document.cookie = `${key}=${value};path=/`
    return value
  }
  if (!document.cookie.includes(`${key}=`)) return
  return document.cookie.split(`${key}=`)[1].split(';')[0]
}

export function getCookieValue(cookie: string, key: string) {
  if (!cookie?.includes(`${key}=`)) return
  let retValue
  const cookies = cookie.split(';')
  cookies.forEach(cookie => {
    const [name, value] = cookie.trim().split('=')
    if (name === key) {
      retValue = value
    }
  })
  return retValue
}

export const truncateToTwoWords = text => {
  const words = text.trim().split(/\s+/)
  if (words.length <= 3) {
    return text
  }
  return words.slice(0, 4).join(' ') + '...'
}

export const getInitialDescription = (description, wordCount) => {
  const words = description.split(' ')
  return words.slice(0, wordCount).join(' ')
}

export const composeMailUrl = url => {
  if (url) return `mailto:${url}`
  return undefined
}

export const findMatchingObject = (dataArray, pageUrl) => {
  // Use the find method to locate the matching object based on the URL
  const matchingObject = dataArray.find((item: any) => item?.attributes?.url?.includes(pageUrl))
  return matchingObject || null
}

export const maskCode = (code: string, unMaskedLength: number) =>
  '*'.repeat(Math.max(0, code.length - unMaskedLength)) + code.slice(-unMaskedLength)

export async function fetchWithTimeout(resource: string, options: { timeout?: number } = {}) {
  const { timeout = FETCH_TIMEOUT } = options

  const controller = new AbortController()
  const id = setTimeout(() => controller.abort(), timeout)

  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  })
  clearTimeout(id)

  return response
}

export async function r2d2(in_, requestUrl) {
  let payload: any = getR2d2Payload({
    ekind: in_.ekind,
    odata: in_.odata,
  })
  payload.edata = JSON.parse(payload?.edata)
  payload.odata = JSON.parse(payload?.odata)
  delete payload.tracker_id
  delete payload?.user_agent

  payload = {
    tracker: in_.tracker || cookie('trackerid'),
    ua: window?.navigator.userAgent,
    ...payload,
  }

  R2D2ServiceApi.sendEvent(requestUrl, payload)
}
